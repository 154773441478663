import Api from "../api";
import {
  GetCategoryListResponse,
  GetResourceListParams,
  GetResourceListResponse,
  ResourceTypes,
} from "./resource.type";

const getResourceByType = async ({
  page,
  page_size,
  type,
  categories,
}: GetResourceListParams) => {
  try {
    const urls = {
      [ResourceTypes.PRODUCT_VIDEOS]: "product-videos",
      [ResourceTypes.BLOGS]: "guides",
      [ResourceTypes.OLD_BLOGS]: "guides",
      [ResourceTypes.CASE_STUDY]: "case-studies",
      [ResourceTypes.RELEASES]: "releases",
    };

    const res = await Api<GetResourceListResponse>({
      url: `/v1/resources/${urls[type]}`,
      method: "GET",
      params: {
        page,
        page_size,
        populate: ["thumbnail", "categories"],
        filters: {
          categories: {
            slug: categories,
          },
        },
        sort: { ["publishedAt"]: "desc" },
      },
    });

    return {
      data: res?.data || [],
      page_count: res?.meta?.pagination?.pageCount || 0,
      total: res?.meta?.pagination?.total || 0,
    };
  } catch (error) {
    return {
      data: [],
      page_count: 0,
      total: 0,
    };
  }
};

const getCategories = async () => {
  try {
    const res = await Api<GetCategoryListResponse>({
      url: "/v1/resources/categories",
      method: "GET",
      params: {
        page: 1,
        page_size: 100,
      },
    });

    return res?.data || [];
  } catch (error) {
    return [];
  }
};

const ResourceApi = { getResourceByType, getCategories };

export default ResourceApi;
