import { ResourceTypes } from "~/services/resource";
import Trans from "~/types/translationKeys";

export const highlightFeatures = [
  {
    title:
      Trans["5_minutes_to_enable_ai_chat_engine_for_both_your_web_and_app"],
    icon: "/svgs/hl_feature_icon_1.svg",
    image: "/svgs/hl_feature_1.svg",
    animation: "/animations/5mins.json",
  },
  {
    title: Trans.hybrid_mode_combine_ai_and_real_human_conversation_at_any_time,
    icon: "/svgs/hl_feature_icon_2.svg",
    image: "/svgs/hl_feature_2.svg",
  },
  {
    title: Trans.auto_study_from_available_data,
    icon: "/svgs/hl_feature_icon_3.svg",
    image: "/svgs/hl_feature_3.svg",
  },
  {
    title:
      Trans.unlimited_free_trial_cancel_anytime_no_commitment_no_data_collected,
    icon: "/svgs/hl_feature_icon_4.svg",
    image: "/svgs/hl_feature_4.svg",
  },
  {
    title: Trans.manage_multi_chat_sessions_labeling,
    icon: "/svgs/hl_feature_icon_5.svg",
    image: "/svgs/hl_feature_5.svg",
  },
];

export const questions = [
  {
    quesiton: Trans.what_is_cx_genie_and_how_does_it_work,
    answer:
      Trans.cx_genie_is_a_platform_designed_to_automate_and_optimize_customer_support_processes_it_uses_natural_language_processing_and_machine_learning_to_provide_automated_efficient_and_personalized_customer_interactions,
  },
  {
    quesiton:
      Trans.what_are_the_key_benefits_of_using_cx_genie_for_our_business,
    answer:
      Trans.cx_genie_maximizes_your_roi_and_offers_a_lot_of_business_benefits_through_improving_customer_engagement_reducing_support_costs_24_7_availability_and_the_ability_to_scale_support_efforts_effortlessly,
  },
  {
    quesiton: Trans.is_cx_genie_suitable_for_businesses_of_all_sizes,
    answer:
      Trans.yes_cx_genie_is_designed_to_cater_to_bussinesses_of_all_sizes_and_categories_from_startups_to_enterprise_level_organizations_whether_you_bring_your_own_code_or_configure_it_in_a_few_clicks_cx_genie_is_easily_modified_to_meet_your_unique_business_needs,
  },
  {
    quesiton:
      Trans.how_does_cx_genie_integrate_with_our_existing_customer_support_tools_and_systems,
    answer:
      Trans.cx_genie_offers_seamless_integration_with_various_customer_support_tools_crm_systems_and_messaging_platforms_our_team_will_work_with_you_to_ensure_a_smooth_integration_process_we_not_only_make_things_easy_on_your_customers_but_set_your_teams_up_for_success_and_keep_your_business_in_sync,
  },
  {
    quesiton: Trans.can_cx_genie_handle_complex_customer_inquiries_and_issues,
    answer:
      Trans.absolutely_cx_genie_s_ai_is_trained_to_handle_a_wide_range_of_customer_inquiries_from_simple_queries_to_complex_issues_it_continuously_learns_and_improves_its_responses_to_better_assist_your_customers,
  },
  {
    quesiton: Trans.is_there_a_need_for_human_intervention_when_using_cx_genie,
    answer:
      Trans.cx_genie_can_handle_a_significant_portion_of_customer_inquiries_autonomously_it_s_designed_to_escalate_complex_issues_to_human_agents_when_necessary_ensuring_a_seamless_customer_experience,
  },
  {
    quesiton: Trans.what_kind_of_analytics_and_insights_does_cx_genie_provide,
    answer:
      Trans.cx_genie_offers_comprehensive_analytics_and_insights_into_customer_interactions_you_can_track_customer_satisfaction_response_times_and_other_key_metrics_to_continually_improve_your_support_processes,
  },
  {
    quesiton:
      Trans.is_cx_genie_secure_and_compliant_with_data_protection_regulations,
    answer:
      Trans.yes_cx_genie_prioritizes_data_security_and_compliance_it_adheres_to_industry_standard_security_practices_and_can_be_customized_to_comply_with_data_protection_regulations_specific_to_your_business,
  },
  {
    quesiton: Trans.how_can_we_get_started_with_cx_genie_for_our_business,
    answer: Trans.to_get_started_with_cx_genie_for_our_business,
  },
  {
    quesiton: Trans.is_my_data_secured,
    answer:
      Trans.yes_your_data_is_secured_with_cx_genie_we_dont_store_your_conversation_with_the_ai_your_uploaded_data_links_and_files_are_stored_on_the_cloud_chatgpt_with_their_standard_security_llm_calls_are_made_using_chatgpt_with_their_security_measures,
  },
];

export const customerSupportAgentsOptions = [
  {
    label: "< 10",
    value: "<10",
  },
  {
    label: "10 - 50",
    value: "10-50",
  },
  {
    label: "50+",
    value: ">50",
  },
];

export const privacyContent = [
  {
    title: Trans.introduction,
    list: [
      {
        title: Trans.purpose_of_the_privacy_policy,
        content:
          Trans.this_privacy_policy_outlines_our_commitment_to_protecting_your_privacy_and_explains_how_we_collect_use_disclose_and_safeguard_your_data_when_you_use_cx_genie,
      },
      {
        title: Trans.scope_of_the_privacy_policy,
        content:
          Trans.this_policy_applies_to_all_users_of_cx_genie_including_customers_and_visitors_to_our_website,
      },
      {
        title: Trans.consent_and_acceptance,
        content:
          Trans.by_using_cx_genie_you_agree_to_the_terms_of_this_privacy_policy_and_consent_to_the_collection_processing_and_sharing_of_your_information_as_described_in_herein,
      },
    ],
  },
  {
    title: Trans.information_we_collect,
    list: [
      {
        title: Trans.personal_information,
        content:
          Trans.we_collect_personal_information_such_as_your_name_email_address_and_contact_details_when_you_create_an_account_or_communicate_with_us,
      },
      {
        title: Trans.non_personal_information,
        content:
          Trans.we_may_collect_non_personal_information_such_as_device_information_browser_type_and_usage_data_to_improve_our_services,
      },
      {
        title: Trans.cookies_and_tracking_technologies,
        content:
          Trans.we_use_cookies_and_similar_technologies_to_enhance_your_experience_on_cx_genie_and_for_analytics_and_marketing_purposes_you_can_manage_your_cookie_preferences_through_your_browser_settings,
      },
      {
        title: Trans.third_party_data_collection,
        content:
          Trans.we_may_integrate_with_third_party_services_that_collect_data_independently_please_review_the_privacy_policies_of_these_third_parties_for_more_information,
      },
    ],
  },
  {
    title: Trans.how_we_use_your_information,
    list: [
      {
        title: Trans.providing_services,
        content:
          Trans.we_use_your_information_to_provide_and_improve_cx_genie_s_services_including_customer_support_automation_and_engagement_features,
      },
      {
        title: Trans.customer_support_automation,
        content:
          Trans.cx_genie_uses_ai_to_automate_customer_support_processes_your_interactions_with_our_platform_may_be_analyzed_to_improve_the_quality_of_responses,
      },
      {
        title: Trans.personalization_and_customer_engagement,
        content:
          Trans.we_may_use_your_data_to_personalize_your_experiences_and_engage_with_you_through_emails_notifications_or_in_app_messages,
      },
    ],
  },
  {
    title: Trans.sharing_your_information,
    list: [
      {
        title: Trans.third_party_service_providers,
        content:
          Trans.we_may_share_your_data_with_third_party_service_providers_who_assist_us_in_delivering_and_improving_cx_genie_s_services,
      },
      {
        title: Trans.legal_requirements,
        content:
          Trans.we_may_disclose_your_information_if_required_by_law_such_as_in_response_to_a_subpoena_or_other_legal_process,
      },
      {
        title: Trans.business_transfers,
        content:
          Trans.in_the_event_of_a_merger_acquisition_or_sale_of_assets_your_data_may_be_transferred_to_the_new_entity,
      },
      {
        title: Trans.aggregated_or_deidentified_data,
        content:
          Trans.we_may_share_aggregated_or_de_identified_data_for_analytics_and_research_purposes,
      },
    ],
  },
  {
    title: Trans.your_privacy_choices,
    list: [
      {
        title: Trans.accessing_and_updating_your_information,
        content:
          Trans.we_may_share_your_data_with_third_party_service_providers_who_assist_us_in_delivering_and_improving_cx_genie_s_services,
      },
      {
        title: Trans.communication_preferences,
        content:
          Trans.you_can_manage_your_communication_preferences_by_adjusting_settings_within_your_cx_genie_account_or_by_unsubscribing_from_emails,
      },
      {
        title: Trans.cookies_and_tracking,
        content:
          Trans.you_can_manage_cookies_and_tracking_preferences_through_your_browser_settings,
      },
      {
        title: Trans.do_not_track_signals,
        content: Trans.we_do_not_currently_respond_to_do_not_track_signals,
      },
    ],
  },
  {
    title: Trans.data_security,
    list: [
      {
        title: Trans.security_measures,
        content:
          Trans.we_employ_industry_standard_security_measures_to_protect_your_data,
      },
      {
        title: Trans.data_breach_response,
        content:
          Trans.in_the_event_of_a_data_breach_we_will_notify_affected_users_in_accordance_with_applicable_laws,
      },
    ],
  },
  {
    title: Trans.third_party_links_and_services,
    list: [
      {
        title: Trans.external_websites,
        content:
          Trans.cx_genie_may_contain_links_to_external_websites_we_are_not_responsible_for_the_privacy_practices_or_content_of_these_websites,
      },
      {
        title: Trans.integration_with_third_party_services,
        content:
          Trans.cx_genie_may_integrate_with_third_party_services_your_use_of_these_services_is_subject_to_their_respective_privacy_policies,
      },
    ],
  },
  {
    title: Trans.childrens_privacy,
    list: [
      {
        title: Trans.age_restrictions,
        content:
          Trans.cx_genie_is_not_intended_for_children_under_the_age_of_13_we_do_not_knowingly_collect_personal_information_from_children_under_13,
      },
      {
        title: Trans.parental_consent,
        content:
          Trans.if_you_believe_that_we_have_collected_information_from_a_child_under_13_without_parental_consent_please_contact_us_to_request_removal_of_that_information,
      },
    ],
  },
  {
    title: Trans.international_users,
    list: [
      {
        title: Trans.cross_border_data_transfer,
        content:
          Trans.by_using_cx_genie_you_consent_to_the_transfer_of_your_data_to_the_united_states_and_other_jurisdictions_as_necessary_for_the_purposes_outlined_in_this_privacy_policy,
      },
      {
        title: Trans.eu_users_rights_if_applicable,
        content:
          Trans.if_you_are_an_eu_user_you_may_have_additional_rights_under_the_general_data_protection_regulation_gdpr_please_see_our_separate_gdpr_privacy_notice_for_more_information,
      },
    ],
  },
  {
    title: Trans.changes_to_this_privacy_policy,
    list: [
      {
        title: Trans.notification_of_changes,
        content:
          Trans.we_will_notify_you_of_any_material_changes_to_this_privacy_policy_through_the_cx_genie_platform_or_via_email,
      },
      {
        title: Trans.effective_date,
        content:
          Trans.the_effective_date_of_this_privacy_policy_is_indicated_at_the_top_of_the_page,
      },
    ],
  },
  {
    title: Trans.contact_information,
    list: [
      {
        title: Trans.data_controller,
        content: Trans.your_company_name_and_address,
      },
      {
        title: Trans.data_protection_officer,
        content: Trans.name_and_contact_information_of_dpo,
      },
      {
        title: Trans.contact_us,
        content:
          Trans.if_you_have_questions_or_concerns_about_this_privacy_policy_or_your_data_please_contact_us_at,
      },
    ],
  },
  {
    title: Trans.additional_information_if_applicable,
    list: [
      {
        title: Trans.california_privacy_rights,
        content:
          Trans.if_you_are_a_california_resident_you_may_have_additional_rights_under_the_california_consumer_privacy_act_ccpa_please_see_our_separate_ccpa_privacy_notice_for_more_information,
      },
      {
        title: Trans.gdpr_data_subject_rights,
        content:
          Trans.if_you_are_an_eu_user_you_have_certain_rights_under_the_general_data_protection_regulation_gdpr_please_see_our_separate_gdpr_privacy_notice_for_more_information,
      },
    ],
  },
];

export const termsContent = [
  {
    title: Trans.introduction,
    content:
      Trans.thank_you_for_choosing_cx_genie_a_cutting_edge_software_as_a_service_saaS_platform_that_leverages_artificial_intelligence_to_automate_and_optimize_the_customer_support_process_cx_genie_is_designed_to_help_you_enhance_customer_engagement_with_minimal_effort_providing_you_with_a_powerful_tool_to_improve_your_customer_service_and_support_operations_please_read_these_terms_of_use_carefully_before_using_our_services,
  },
  {
    title: Trans.acceptance_of_terms,
    content:
      Trans.by_accessing_or_using_cx_genie_you_agree_to_be_bound_by_these_terms_of_use_if_you_do_not_agree_with_any_part_of_these_terms_please_do_not_use_our_services_these_terms_represent_a_legally_binding_agreement_between_you_and_cx_genie_so_it_s_important_to_understand_them_fully,
  },
  {
    title: Trans.changes_to_terms,
    content:
      Trans.cx_genie_reserves_the_right_to_modify_these_terms_of_use_at_any_time_we_will_notify_you_of_any_changes_by_posting_the_updated_terms_on_our_website_or_through_other_means_of_communication_it_is_your_responsibility_to_review_the_terms_periodically_and_your_continued_use_of_our_services_after_any_changes_constitutes_acceptance_of_those_changes,
  },
  {
    title: Trans.user_accounts,
    list: [
      {
        title: Trans.registration,
        content:
          Trans.to_use_cx_genie_you_may_be_required_to_create_a_user_account_you_agree_to_provide_accurate_complete_and_up_to_date_information_during_the_registration_process_you_are_solely_responsible_for_maintaining_the_confidentiality_of_your_account_credentials,
      },
      {
        title: Trans.access_control,
        content:
          Trans.you_are_responsible_for_all_activities_that_occur_under_your_account_you_agree_not_to_share_your_account_information_and_you_are_responsible_for_ensuring_that_no_unauthorized_person_gains_access_to_your_account,
      },
    ],
  },
  {
    title: Trans.use_of_cx_genie,
    list: [
      {
        title: Trans.permitted_use,
        content:
          Trans.you_may_use_cx_genie_for_its_intended_purpose_which_is_to_enhance_customer_support_and_engagement_this_includes_using_the_platform_s_ai_powered_features_and_tools_as_described_in_our_documentation,
      },
      {
        title: Trans.prohitted_activities,
        content:
          Trans.you_agree_not_to_engage_in_any_activities_that_are_illegal_harmful_or_violate_the_rights_of_others_while_using_cx_genie_this_includes_but_is_not_limited_to_spamming_distributing_malware_or_infringing_on_intellectual_property_rights,
      },
    ],
  },
  {
    title: Trans.subscription_and_payment,
    list: [
      {
        title: Trans.subscription_plans,
        content:
          Trans.cx_geni_offers_various_subscription_plans_each_with_its_own_features_and_pricing_by_subscribing_to_a_plan_you_agree_to_pay_the_associated_fees_as_described_on_our_website,
      },
      {
        title: Trans.billing,
        content:
          Trans.payments_are_processed_through_secure_third_party_payment_processors_you_agree_to_provide_accurate_billing_information_and_authorize_us_or_our_payment_processor_to_charge_your_chosen_payment_method,
      },
      {
        title: Trans.cancellation,
        content:
          Trans.you_can_cancel_your_subscription_at_any_time_and_your_access_to_cx_genie_will_continue_until_the_end_of_your_current_billing_cycle_no_refunds_will_be_provided_for_partial_subscription_periods,
      },
    ],
  },
  {
    title: Trans.privacy_and_data_security,
    content:
      Trans.cx_genie_takes_your_privacy_and_data_security_seriously_our_privacy_policy_outlines_how_we_collect_use_and_protect_your_personal_information_you_can_read_our_privacy_policy_to_understand_our_policies_in_detail,
  },
  {
    title: Trans.termination_of_service,
    content:
      Trans.cx_genie_reserves_the_right_to_terminate_or_suspend_your_access_to_our_services_at_our_discretion_without_prior_notice_for_violations_of_these_Terms_of_Use_or_any_other_reason_we_deem_appropriate,
  },
  {
    title: Trans.disclaimer_of_warranties,
    content:
      Trans.cx_genie_provides_its_services_as_is_and_as_available_we_make_no_warranties_express_or_implied_regarding_the_accuracy_reliability_or_availability_of_our_services_you_use_cx_genie_at_your_own_risk,
  },
  {
    title: Trans.limitation_of_liability,
    content:
      Trans.cx_genie_shall_not_be_liable_for_any_direct_or_indirect_damages_incidental_to_or_consequential_dames_whatsoever_in_connection_with_your_use_of_our_services_even_if_we_have_been_advised_of_the_possibility_of_such_damages,
  },
  {
    title: Trans.governing_law,
    content:
      Trans.these_terms_of_use_are_governed_by_and_confronted_in_accordance_with_the_laws_of_your_jurisdiction_without_regard_to_its_conflict_of_law_principles,
  },
  {
    title: Trans.contact_us,
    content:
      Trans.if_you_have_any_questions_concerns_or_feedback_regarding_these_terms_of_use_or_our_services_please_contact_us_at_hello_cxgenie_ai_by_using_cx_genie_you_acknowledge_that_you_have_read_understand_and_agree_to_these_terms_of_use_we_hope_you_find_cx_genie_to_be_a_valuable_tool_for_enhancing_your_customer_engagement_and_support_efforts,
  },
];

export const resourceNameByType: Record<ResourceTypes, string> = {
  [ResourceTypes.BLOGS]: Trans.blogs,
  [ResourceTypes.OLD_BLOGS]: Trans.blogs,
  [ResourceTypes.CASE_STUDY]: Trans.case_studies,
  [ResourceTypes.PRODUCT_VIDEOS]: Trans.product_videos,
  [ResourceTypes.RELEASES]: Trans.releases,
};

export const features = [
  {
    icon: "/svgs/feature_1.svg",
    thumb: "/svgs/feature_thumb_1.svg",
    title: Trans.easy_to_use_workspace,
    description:
      Trans.everything_you_need_to_manage_your_chatbot_easily_accessible_in_one_place,
    features: [
      [{ name: Trans.view_real_time_performance_in_your_dashboard }],
      [{ name: Trans.manage_all_bots_in_one_place }],
      [{ name: Trans.jump_into_live_chats }],
      [{ name: Trans.build_custom_message_flows }],
      [{ name: Trans.manage_customers_and_tickets }],
    ],
  },
  {
    icon: "/svgs/feature_2.svg",
    thumb: "/svgs/feature_thumb_2.svg",
    title: Trans.multiple_data_sources,
    description:
      Trans.enrich_and_train_your_chatbot_with_diverse_information_types_multiple_data_sources_ensure_the_chatbot_provides_accurate_and_comprehensive_responses,
    include_demo: true,
    features: [
      [{ name: Trans.upload_urls_faqs_documents_and_more }],
      [{ name: Trans.train_on_rich_content }],
      [{ name: Trans.supporting_multimedia_interactions }],
    ],
  },
  {
    icon: "/svgs/feature_3.svg",
    thumb: "/svgs/feature_thumb_3.svg",
    title: Trans.hybrid_model,
    description:
      Trans.blend_the_best_of_both_worlds_combine_ai_and_real_human_conversation_at_any_time_for_personalized_support,
    features: [
      [{ name: Trans.human_touch_meets_automation }],
      [{ name: Trans["24_7_availability"] }],
      [{ name: Trans.data_driven_decisions }],
    ],
  },
  {
    icon: "/svgs/feature_4.svg",
    thumb: "/svgs/feature_thumb_4.svg",
    title: Trans.message_flow_builder,
    description:
      Trans.build_dynamic_chatbot_conversations_that_feel_human_not_robotic_design_personalized_interactive_and_unique_conversation_paths_based_on_customers_choices_ensuring_they_get_the_answers_and_support_they_need_no_coding_required,
    features: [
      [{ name: Trans.map_the_journey_and_build_branching_conversations }],
      [{ name: Trans.visualize_the_flow_with_drag_and_drop_interface }],
      [
        {
          name: Trans.personalize_at_every_turn_branching_logic_conditional_responses,
        },
      ],
      [{ name: Trans.collect_feedback }],
    ],
  },
  {
    icon: "/svgs/feature_5.svg",
    thumb: "/svgs/feature_thumb_5.svg",
    title: Trans.ai_recommendations,
    description:
      Trans.chatbot_agents_learn_and_respond_in_real_time_imagine_your_customers_feeling_seen_heard_and_understood_that_s_the_power_of_ai_recommendations_in_action,
    features: [
      [{ name: Trans.ai_auto_reply_and_suggestions }],
      [{ name: Trans.proactive_engagement_every_time }],
      [{ name: Trans.reduce_support_tickets_boost_conversions }],
    ],
  },
  {
    icon: "/svgs/feature_6.svg",
    thumb: "/svgs/feature_thumb_6.svg",
    title: Trans.your_favorite_platforms_integrated,
    description:
      Trans.easily_integrate_your_chatbot_with_your_chosen_platforms_and_stay_tuned_more_cx_genie_integrations_coming_soon,
    features: [
      [
        { name: "Messenger", icon: "/svgs/messenger.svg", width: 20 },
        { name: "Whatsapp", icon: "/svgs/whatsapp.svg", width: 20 },
      ],
      [
        { name: "Wordpress", icon: "/svgs/wordpress.svg", width: 20 },
        { name: "Shopify", icon: "/svgs/shopify.svg", width: 18 },
        {
          name: Trans.google_sheet_and_more,
          icon: "/svgs/google_sheet.svg",
          width: 15,
        },
      ],
      [
        {
          name: Trans.let_us_know_what_integrations_you_need_at,
          link: "hello@cxgenie.ai",
          href: "mailto:hello@cxgenie.ai",
        },
      ],
    ],
    include_demo: true,
  },
];

export const keyFeatures = [
  {
    icon: "/svgs/key_feature_1.svg",
    name: Trans.auto_learning_and_human_powered_improvement,
    description:
      Trans.forget_static_responses_learn_from_every_conversation_adapts_to_user_behavior_and_constantly_improves_with_human_feedback_it_s_the_chatbot_that_gets_smarter_every_day,
  },
  {
    icon: "/svgs/key_feature_2.svg",
    name: Trans.effective_communication_naturally,
    description:
      Trans.friendly_interactions_with_emojis_you_got_it_you_got_it_cx_genie_follows_conversation_flow_remembers_context_and_uses_natural_language_for_engaging_experiences_your_customers_will_feel_like_theyre_talking_to_a_human_not_a_robot,
  },
  {
    icon: "/svgs/key_feature_3.svg",
    name: Trans.feedback_loop_for_success,
    description:
      Trans.collect_feedback_directly_in_your_message_flow_use_like_and_unlike_reactions_to_understand_user_sentiment_and_optimize_your_chatbots_responses_for_maximum_satisfaction,
  },
  {
    icon: "/svgs/key_feature_4.svg",
    name: Trans.intent_detection_and_personalized_messaging,
    description:
      Trans.no_more_generic_responses_analyze_user_input_understand_intent_and_deliver_personalized_messages_tailored_to_your_individual_needs_and_preferences,
  },
];

export const audiences = [
  {
    name: Trans.customer_service_teams,
    description:
      Trans.strugging_with_high_contact_volumes_and_repetitive_inquiries_cx_genie_empowers_you_to_automate_faqs_offer_24_7_support_and_personalize_interactions_to_delight_customers_and_free_up_your_teams_time,
  },
  {
    name: Trans.e_commerce_and_retail_businesses,
    description:
      Trans.want_to_engage_customers_provide_real_time_assistance_and_offer_product_recommendations_cx_genie_helps_you_personalize_the_buying_journey_boost_sales_and_build_brand_loyalty_with_ai,
  },
  {
    name: Trans.marketing_and_sales_teams,
    description:
      Trans.looking_to_improve_lead_generation_qualify_prospects_nurture_relationships_cx_genie_lets_you_capture_valuable_customer_data_answer_questions_instantly_and_convert_leads_into_sales_with_interactive_chat_experiences,
  },
  {
    name: Trans.financial_services,
    description:
      Trans.need_to_provide_personalized_communication_offer_advice_and_streamline_account_management_cx_genie_helps_you_answer_customer_inquiries_24_7_address_concerns_and_build_trust,
  },
  {
    name: Trans.technology_and_software_startups,
    description:
      Trans.want_to_gather_product_feedback_prioritize_feature_requests_and_engage_directly_with_users_cx_genie_allows_you_to_collect_valuable_insights_automate_support_interactions_and_improve_your_product_roadmap,
  },
];

export const brands = [
  { name: "eastplayers", icon: "/svgs/brand_1.svg", width: 157 },
  { name: "agene", icon: "/svgs/brand_2.svg", width: 113 },
  { name: "your ai cheatsheet", icon: "/svgs/brand_3.svg", width: 128 },
  { name: "mfast", icon: "/svgs/brand_4.svg", width: 131 },
  { name: "anawan", icon: "/svgs/brand_5.svg", width: 194 },
  { name: "the frame", icon: "/svgs/brand_6.svg", width: 147 },
];

export const AppsumoTiers = [
  {
    name: Trans.appsumo_license_tire_1,
    price: "$216",
    discounted_price: "$59",
    max_monthly_conversation: 5000,
    max_document: 50,
    max_chatbot: 20,
    max_member: 5,
    tier: 1,
  },
  {
    name: Trans.appsumo_license_tire_2,
    price: "$264",
    discounted_price: "$149",
    max_monthly_conversation: 25000,
    max_document: -1,
    max_chatbot: 50,
    max_member: 20,
    tier: 2,
  },
  {
    name: Trans.appsumo_license_tire_3,
    price: "$480",
    discounted_price: "$289",
    max_monthly_conversation: 50000,
    max_document: -1,
    max_chatbot: -1,
    max_member: -1,
    tier: 3,
  },
];

export const appSumo = {
  name: Trans.life_time_deal,
  origin_price: "$69.0",
  discount_price: "$1,499",
  sub_name: Trans.special_offer_only_on,
  feature_1: [
    Trans["30000_messages_month"],
    Trans["50_documents_for_ai_knowledge_files_faqs_articles_urls"],
    Trans.install_chatbot_on_website_mobile_app,
    Trans.hybrid_chat_mode_for_agent_with_ai_recommendation,
  ],
  feature_2: [
    Trans.max_30_members,
    Trans.usage_reports,
    Trans.api_access,
    Trans.live_chat_technical_support,
    Trans.ai_model_openai_gpt4,
  ],
  terms: [
    Trans[
      "60_day_money_back_guarantee_try_it_out_for_2_months_to_make_sure_it_is_right_for_you"
    ],
    Trans.lifetime_access_to_cx_genie,
    Trans.all_future_updates,
    Trans.all_future_ai_model_updates,
    Trans.if_plan_name_changes_deal_will_be_mapped_to_the_new_plan_name_with_all_accompanied_updates,
    Trans.no_codes_no_stacking_just_choose_the_plan_that_s_right_for_you,
  ],
  conditions: [
    Trans.you_must_activate_your_plan_within_60_days_of_purchase,
    Trans.gdpr_compliant,
  ],
};

export const LanguageOptions = [
  {
    icon: "/svgs/flag_en.svg",
    value: "en",
    label: "English",
    shortLabel: "EN",
  },
  {
    icon: "/svgs/flag_vi.svg",
    value: "vi",
    label: "Vietnamese",
    shortLabel: "VI",
  },
];

export const Services = [
  {
    name: Trans.ai_agent_deployment_and_customization,
    content:
      Trans.we_set_up_and_fine_tune_ai_assistants_to_suit_your_requirements_ensuring_seamless_integration_with_your_existing_workflows,
    image: "/images/our_service_1.png",
  },
  {
    name: Trans.ai_application_development,
    content:
      Trans.our_team_crafts_specialized_applications_using_advanced_ai_technology_precisely_tailored_to_your_specifications_and_business_objectives,
    image: "/images/our_service_2.png",
  },
  {
    name: Trans.ai_consultation_services,
    content:
      Trans.our_expert_consultancy_provide_personalized_ai_consultation_sessions_to_help_you_navigate_the_complexities_of_ai_adoption_within_your_business_ensuring_you_make_informed_decisions_that_align_with_your_goals,
    image: "/images/our_service_3.png",
  },
];

export const WorkingProcessStages = [
  {
    name: Trans.deploy_cx_version_to_the_enterprise_server,
    contents: [
      Trans.clarify_requirements_and_customize_features_suitable_for_your_business,
      Trans.finalize_feature_updates_such_as_direct_database_connections_and_learning_from_real_conversations,
    ],
  },
  {
    name: Trans.data_analysis_and_core_ai_engine_development,
    contents: [
      Trans.utilize_client_data_for_ai_training_enriching_the_knowledge_base,
      Trans.develop_the_core_ai_engine_tailored_to_your_business_logic,
    ],
  },
  {
    name: Trans.implement_customization_features,
    contents: [
      Trans.create_and_implement_ai_applications_leading_to_the_core_ai_engine,
      Trans.seamless_integration_with_internal_systems,
      Trans.generate_comprehensive_performance_reports,
    ],
  },
  {
    name: Trans.guide_businesses_to_use_application_and_provide_ongoing_support,
    contents: [
      Trans.gather_weekly_for_feedback_sessions_continuous_enhancements_and_application_refinements,
    ],
  },
  {
    name: Trans.guide_businesses_to_use_application_and_provide_ongoing_support,
    contents: [
      Trans.provide_ongoing_maintenance_and_support_including_regular_updates_and_user_assistance,
    ],
  },
];

export const Features = [
  {
    name: Trans.proven_leaders,
    content:
      Trans.backed_by_a_team_of_builders_and_entrepreneurs_with_a_strong_track_record_of_success_in_tech_companies_,
    image: "/images/feature_1.png",
  },
  {
    name: Trans.ai_expertise,
    content:
      Trans.we_dont_just_provide_ai_we_deliver_a_competitive_advantage_our_custom_ai_solutions_drive_generative_impact_boost_revenue_and_position_you_ahead_of_your_competitors,
    image: "/images/feature_2.png",
  },
  {
    name: Trans.agile_communicative,
    content:
      Trans.were_flexible_collaborators_adapting_to_your_evolving_needs_and_ensuring_clear_communication_throughout_the_journey_you_ll_never_be_left_in_the_dark_with_the_cx_genie_team_by_your_side,
    image: "/images/feature_3.png",
  },
];

export const CaseStudies = [
  {
    image: "/images/case_study_1.png",
    name: Trans.enhanced_and_implemented_an_nlp_model_resulting_in_a_55_boost_in_auditor_efficiency,
    content:
      Trans.the_technical_team_had_reached_an_impasse_when_the_accuracy_of_their_mvp_model_leveled_off,
  },
  {
    image: "/images/case_study_2.png",
    name: Trans.implemented_automation_for_a_startup_s_due_pledge_procedure_resulting_in_a_25_improvement_in_efficiency,
    content:
      Trans.they_depended_on_a_manual_approach_for_gathering_security_survey_data,
  },
  {
    image: "/images/case_study_3.png",
    name: Trans.implemented_nlp_based_automation_for_a_security_compliance_startup_s_due_pledge_process,
    content:
      Trans.the_company_faced_challenges_in_automating_the_identification_of_business_expenses_and_categorizing_customer_transactions_diminishing_the_effectiveness_of_their_product_within_a_month,
  },
  {
    image: "/images/case_study_4.png",
    name: Trans.developed_and_implemented_a_strategy_to_launch_a_financial_platform_from_inception_to_initial_development,
    content:
      Trans.the_company_aimed_to_automate_a_labor_intensive_process_of_categorizing_transactions_as_either_personal_or_business_expenses_unfortunately_they_lacked_the_technical_proficiency_to_construct_and_deploy_a_tailored_machine_learning_architecture_and_model,
  },
];

export const discordBenefits = [
  {
    name: Trans.talk_to_devs,
    content: Trans.get_real_time_support_and_insights,
  },
  {
    name: Trans.shape_cx_genie,
    content: Trans.share_feedback_report_bugs_suggest_new_features,
  },
  {
    name: Trans.insider_knowledge,
    content: Trans.learn_tips_tricks_from_our_team_and_other_users,
  },
  {
    name: Trans.unlock_exclusives,
    content: Trans.free_add_ons_and_and_new_features_for_free,
  },
];

export const customerFeedbacks = [
  {
    avatar: "/images/client_1.png",
    name: "natasha00",
    title: "The more I train it, the better it gets. Highly recommend!",
    description:
      "CX Genie is beneficial! The real magic happens when you invest time building the chatbot's knowledge base. The more you feed it with answers to common questions, the better it responds to customer inquiries. This has significantly reduced my time on repetitive customer interactions, freeing me to focus on other issues. Highly recommend!",
    star: 5,
  },
  {
    avatar: "/images/client_2.png",
    name: "ljs.graphics1",
    title: "The mobile app and live chat is Amazing",
    description:
      "Overall, this tool is exceptionally strong. Its responsiveness is impressive, and the seamless transition to live chat handover enhances the user experience. The auto-training feature, leveraging previous conversations, is a standout feature, offering unparalleled efficiency.",
    star: 5,
  },
  {
    avatar: "/images/client_3.png",
    name: "jass_",
    title: "Great  product overall",
    description:
      "This tool is very good overall. Responds well, human handover is awesome, auto-training with previous conversations is fantastic.",
    star: 5,
  },
  {
    avatar: "/images/client_4.png",
    name: "seb_",
    title: "Works really great!",
    description:
      "I love how easy it was to set it up and train. Click here, click there - ready. Discord is alive and full of support from the CX Genie team.",
    star: 5,
  },
  {
    avatar: "/images/client_5.png",
    name: "Waldemar_Ramos",
    title:
      "This chatbot replaces more than 6 software and provides the entire customer service operation",
    description:
      "At the time of writing this review, the CX Genie needs some tweaking and fine-tuning, however, it has already proven to be a fantastic tool that will greatly help my operation.<br/><br/>I'm replacing several complex software and server expenses, such as: Flowise, Botpress, Typebot, N8N, VPS, GPT 4, among others, with just one software that does all this integrated and easy without any code.<br/><br/>Another important point for my evaluation is the attention and quick feedback given by the developers. The support is excellent and they welcome our suggestions for improvements. I recommend that they remain that way.<br/><br/>I'm excited about the upcoming product updates and see potential for it to be the best AI-powered LLM and support tool for sure.",
    star: 5,
  },
  {
    avatar: "/images/client_6.png",
    name: "apps007",
    title: "Lots of potential",
    description:
      "They seem to be focusing on functionality that a lot of bots haven't begun to think about as yet:<br/>1. Seamless integration between human agents and AI agents.<br/>2. Educating the AI based on human agents input.<br/>3. Workflows to enhance how the bot interacts with people.<br/><br/>There are still some bugs here and there but on the whole it's a usable product with a lot of potential.",
    star: 4,
  },

  {
    avatar: "/images/client_8.png",
    name: "Tony_Upbase",
    title: "Powerful AI assistant with mobile app & easy handover",
    description:
      "I purchased CX Genie, and overall, I think it's a well-rounded AI assistant platform.<br/><br/>The integration with Facebook Messenger is quick and easy. Customers can directly interact with the bot within Messenger. The CX Genie also has a mobile app which adds convenience, as the support team can receive notifications on the go.<br/><br/>The AI to Human handover feature is something I was looking for and here its done in a very simple way. CX Genie automatically sends a notification to a human agent when there is a complex question that it doesn’t have the answer to, or a message flow can be created to trigger a human agent. Theres a smooth transition from AI to human assistance when needed.<br/><br/>Overall, CX Genie offers excellent value for its price, and I appreciate the ongoing updates and improvements from the CX Genie team.",
    star: 5,
  },
  {
    avatar: "/images/client_7.png",
    name: "onecup",
    title: "Amazing Platform",
    description:
      "This platform is a game-changer. It's incredibly well-designed, and it's evident that the developers know exactly what they're doing. I'm thrilled to have it.",
    star: 5,
  },
  {
    avatar: "/images/client_9.png",
    name: "info9927k",
    title: "Actively being developed",
    description:
      "CX Genie has a solid base and is being developed be a very good support team. They also take every bit of feedback on board in the discord channel. My only concern with the Tier 3 plan was the lack of white labelling for unlimited bots was pointless but Ethan assured me that they are going to review that decision at the end of June.",
    star: 5,
  },
  {
    avatar: "/images/client_10.png",
    name: "dinnoleon",
    title: "The Cochinita Pibil of chatbots",
    description:
      "5 tacos calientes for CX Genie. The feature set is extensive, and the interface is intuitive, so I had no trouble finding my way around and making good use of its capabilities. On top of that, CX Genie's analytics and insights have been crucial in empowering me to make data-driven decisions that have improved our customer experience. On the whole, CX Genie has exceeded my expectations, and I would suggest it to anybody seeking to improve their customer service processes.",
    star: 5,
  },
];
